.form {
  padding: 50px 40px 1px;
  border-radius: 28px;
  background-color: #fbfbfb;
  margin-bottom: 60px;
  &__block {
    margin-bottom: 60px; }
  h3 {
    margin-bottom: 30px; }
  &__input {
    margin-bottom: 30px;
    span {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      margin-left: 30px;
      margin-bottom: 10px;
      display: block;
      @include r(1366) {
        font-size: 14px; } }
    input, textarea {
      border-radius: 28px;
      border: solid 1px #afafaf;
      background-color: #ffffff;
      padding: 18px 30px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.19;
      @include placeholder-color(rgba(#000, 0.3));
      color: #000000;
      width: 100%;
      @include r(1366) {
        font-size: 14px;
        padding: 16px 30px; } }
    textarea {
      height: 225px; } }
  &__recaptcha {
    margin-bottom: 30px; }
  &__checkbox {
    input {
      display: none;
      &:checked + label svg {
        fill: #000; } }
    label {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      padding-left: 40px;
      position: relative;
      display: block;
      margin-bottom: 30px;
      cursor: pointer;
      @include r(1366) {
        font-size: 14px; }
      &:hover {
        text-decoration: underline; }
      &::before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: solid 1px #000000;
        display: block;
        position: absolute;
        left: 0;
        top: 0; }
      svg {
        position: absolute;
        left: 4px;
        top: 5px;
        width: 13px;
        height: 13px;
        fill: transparent;
        @include tr; } } } }
