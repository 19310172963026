.home-causes {
  background-image: url('../img/home/divider.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  padding-bottom: 150px;
  @include r(1366) {
    background-size: 1600px auto;
    background-position: 10% 100%; }
  @include r(767) {
    padding-bottom: 90px;
    background-size: 1200px auto; }
  &__block {
    @include r(992) {
      margin-bottom: 60px; } }
  &__text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h2 {
      margin-bottom: 30px;
      @include r(1366) {
        margin-bottom: 20px; } }
    p {
      margin-bottom: 30px;
      max-width: 550px;
      width: 100%;
      display: inline-block;
      @include r(767) {
        margin-bottom: 20px; } }
    ul {
      margin-bottom: 20px; }
    li {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.38;
      color: #707070;
      @include r(1366) {
        font-size: 14px; } }
    &--secondary {
      align-items: flex-end;
      @include r(767) {
        align-items: flex-start; }
      h2 {
        text-align: right;
        @include r(767) {
          text-align: left; } }
      p, li {
        text-align: right;
        @include r(767) {
          text-align: left; } } } }
  &__img {
    @include center;
    img {
      width: 105%;
      @include r(1366) {
        width: 91%; }
      @include r(992) {
        width: 60%; }
      @include r(767) {
        width: 100%;
        margin-bottom: -20px; } } } }

