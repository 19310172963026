.home-intro {
  min-height: 850px;
  background-image: url('../img/home/home-header-bg.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: 50% 100%;
  @include center;
  padding-bottom: 300px;
  margin-bottom: -100px;
  position: relative;
  @include r(1700) {
    @include cover; }
  @include r(1366) {
    min-height: 720px;
    background-position: 0 100%; }
  @include r(992) {
    background-position: 32% 100%;
    padding-top: 240px;
    padding-bottom: 380px;
    min-height: auto;
    display: block; }
  &__img {
    display: none;
    @include r(992) {
      display: block;
      @include relative-x;
      width: 240px;
      position: absolute;
      top: 170px; } }
  &__title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include r(1366) {
      padding-top: 50px; }
    h1 {
      color: #484848;
      max-width: 600px;
      width: 100%;
      text-align: right;
      margin-bottom: 20px;
      @include r(1366) {
        margin-bottom: 35px !important;
        font-size: 40px; }
      @include r(767) {
        font-size: 32px; } }
    ul {
      margin-bottom: 20px;
      list-style: none;
      @include r(1366) {
        margin-bottom: 15px; } }
    li {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;
      text-align: right;
      color: #707070;
      padding-right: 40px;
      position: relative;
      @include r(1366) {
        font-size: 16px;
        margin-bottom: 10px; }
      &::before {
        content: '';
        position: absolute;
        right: 4px;
        top: 8px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 4px solid #fff;
        background: $main_blue;
        box-shadow: 0 0 0 2px $main_blue; } } } }
