.article {
  padding-top: 60px;
  h3, h2 {
    margin: 40px 0 20px; }
  &__img {
    overflow: hidden;
    border-radius: 28px;
    width: 100%;
    max-width: 1000px;
    height: 680px;
    margin-bottom: 45px;
    @include r(992) {
      height: 400px; }
    img {
      width: 100%;
      height: 100%;
      @include object-fit(cover); } }
  ol {
    margin-bottom: 10px; }
  p {
    span {
      display: block; } }
  strong {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: #707070;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block; }
  &__text {
    margin-bottom: 100px;
    &__img {
      img {
        max-width: 420px;
        width: 100%; } } }
  &__title {
    margin-bottom: 35px;
    h1 {
      @include r(450) {
        font-size: 28px; } }
    span {
      font-size: 16px;
      font-weight: normal;
      line-height: 2.19;
      color: #707070; } }


  &-blog {
    .article__img {
      height: 250px;
      @include r(1366) {
        height: 325px; }
      @include r(767) {
        height: 250px; } }
    .article__text {
      h3 {
        font-size: 26px;
        font-weight: 500;
        line-height: 0.85;
        color: #191919;
        margin: 40px 0 20px;
        position: relative;
        svg {
          position: absolute;
          left: -50px;
          top: 0px;
          font-size: 28px;
          fill: #940e0e;
          @include r(767) {
            left: -35px; } } } } }

  &.blog {
    .aside {
      @include r(992) {
        display: none; } }
    h1 {
      margin-bottom: 50px;
      @include r(767) {
        margin-bottom: 30px; } }
    .article__title {
      margin-bottom: 25px; }
    .article__text {
      margin-bottom: 60px;
      .btn {
        margin-top: 25px; } }
    .blog__nav {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 150px;
      @include r(460) {
        flex-wrap: wrap; }
      a {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        color: $main_blue;
        padding: 5px 15px;
        @include r(460) {
          font-size: 16px;
          padding: 5px 10px;
          margin-bottom: 10px;
          display: block; }
        &:hover {
          text-decoration: underline; } }
      li:before {
        display: none; }
      li.disabled {
        a {
          color: #707070;
          &:hover {
            cursor: default;
            text-decoration: none; } } } } }



  &.why {
    .article__title {
      margin-bottom: 50px; }
    h1 {
      margin-bottom: 20px; }
    .why__items {
      padding-left: 50px;
      margin-bottom: 50px;
      .btn {
        @include r(767) {
          @include relative-x;
          left: calc(50% - 15px); } }
      .section-reviews__block {
        margin-bottom: 20px;
        @include r(767) {
          margin-left: -35px;
          width: calc(100% + 35px); }
        strong {
          color: #707070; }
        span {
          display: inline-block;
          font-weight: 500;
          text-transform: none;
          color: #707070; } }
      @include r(767) {
        padding-left: 35px; }
      &__img {
        img {
         max-width: 500px;
         width: 100%; } }
      .article__text {
        margin-bottom: 25px;
        h3 {
          margin: 0;
          margin-bottom: 20px; } } } } }
