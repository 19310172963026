.contact {
  padding-top: 60px;
  overflow: hidden;
  &__title {
    margin-bottom: 70px;
    @include r(767) {
      margin-bottom: 30px; }
    h1 {
      max-width: 880px;
      width: 100%; }
    p {
      color: #191919; } }
  &__img {
    height: 90%;
    @include center;
    justify-content: flex-start;
    @include r(992) {
      display: none; }
    img {
      width: 140%; } }
  &__aside {
    padding: 50px 40px;
    max-width: 385px;
    width: 100%;
    @include r(767) {
      padding: 40px 0; }
    h3 {
      margin-bottom: 30px;
      @include r(767) {
        margin-bottom: 20px; } }
    p {
      margin-bottom: 20px; }
    strong {
      font-weight: 500;
      display: block;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      color: #191919; }
    &__block {
      margin-bottom: 50px; }
    &__item {
      margin-bottom: 30px;
      padding-left: 30px;
      a {
        font-size: 16px;
        font-weight: 500;
        color: $main_blue;
        display: inline-flex;
        position: relative;
        &:hover {
          text-decoration: underline; }
        svg {
          margin-right: 12px;
          width: 16px;
          height: 16px;
          position: absolute;
          left: -30px;
          top: 3px;
          fill: #707070; } }
      b {
        a {
          svg {
            transform: rotate(-90deg); } }
        span {
          display: block;
          font-size: 16px;
          font-weight: 500;
          color: #191919; } }
      p {
        font-size: 16px;
        font-weight: 500;
        color: #191919;
        display: inline-flex;
        position: relative;
        svg {
          margin-right: 12px;
          width: 18px;
          height: 16px;
          position: absolute;
          left: -30px;
          top: 3px;
          fill: #707070; } } }
    &__social {
      display: flex;
      img {
        width: 42px;
        margin-right: 15px; } } } }
