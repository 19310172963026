.header-statistics {
  display: flex;
  li {
    width: 100px;
    height: 50px;
    border-radius: 26px;
    background-color: #dfebff;
    padding: 13px 13px 8px;
    @include r(1366) {
      width: 73px;
      height: 35px;
      padding: 7px 7px 5px; }
    a {
      display: flex;
      align-items: center; }
    strong {
      svg {
        width: 25px;
        height: 30px;
        fill: #000;
        margin-right: 6px;
        @include r(1366) {
          width: 15px;
          height: 22px; } } }
    .stars {
      display: flex;
      svg {
        width: 7px;
        height: 7px;
        margin: 0 1px;
        @include r(1366) {
          width: 5px;
          height: 5px; } } }
    b {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.29;
      color: #707070;
      @include r(1366) {
        font-size: 12px; } } } }

.header {
  width: 100%;
  padding-bottom: 100px;
  padding-top: 5px;
  top: 0;
  left: 0;
  background-image: url('../img/subpages_header_bg.png');
  background-size: 1920px auto;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  z-index: 999;
  position: fixed;
  @include rmin(1920) {
    background-size: 100% auto; }
  @include r(1366) {
    background-size: auto 100%; }
  @include r(992) {
    background-position: 50% 100%;
    padding-bottom: 80px;
    background-size: 100%; }
  @include r(880) {
    background-size: auto 100%; }
  &.scroll {
    top: -100%;
    // +r(1366)
    //   top: -70px
    .header__logo {
      margin-left: 0;
      @include r(992) {
        // max-width: 365px
        margin-left: 0; }
      img {
        top: 10px;
        @include r(992) {
          top: 0; } } } }
  &--home {
    background-image: linear-gradient(to bottom, #000000 -8%, #919eb9 46%, rgba(193, 193, 226, 0.54) 66%, rgba(217, 209, 246, 0) 81%);
    @include r(992) {
      background-position: auto; }
    .header__logo {
      max-width: 350px;
      margin-left: -50px;
      @include r(1366) {
        max-width: 300px; }
      @include r(992) {
        margin-left: 0; }
      img {
        top: -55px;
        @include r(992) {
          top: 0; } } }
    .header__row ul ul {
      background: rgba(#191919, 0.75) !important; }
    p {
      opacity: 0; }
    &.scroll {
      .header__logo {
        margin-left: -50px;
        @include r(1366) {
          max-width: 300px; }
        @include r(992) {
          margin-left: 0; } } } }
  .menu-open {
    position: absolute;
    left: 15px;
    top: 50px;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    background-color: $main_blue;
    display: none;
    z-index: 99;
    padding-top: 3px;
    @include r(992) {
      @include center;
      position: fixed;
      left: 25px;
      top: 30px; }
    svg {
      font-size: 18px; } }
  .mobile-tel {
    width: 45px;
    height: 45px;
    position: absolute;
    right: 20px;
    top: 50px;
    transform: rotate(-90deg);
    display: none;
    z-index: 2;
    @include r(992) {
      @include center;
      top: 30px; }
    svg {
      fill: $main_blue;
      font-size: 30px; } }
  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.29;
    color: #ffffff;
    display: flex;
    align-items: center;
    height: 100%;
    margin-bottom: 0;
    @include r(1366) {
      font-size: 12px; }
    @include r(992) {
      display: none; } }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include r(992) {
      justify-content: center; } }
  &__slogan {
    height: 75px;
    @include r(1366) {
      height: 65px; }
    @include r(992) {
      display: none; }
    img {
      height: 100%; } }
  &__btn {
    display: flex;
    align-items: center;
    @include r(992) {
      display: none; }
    &__tel {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: #98e3e0;
      @include r(1366) {
        font-size: 12px; }
      &:hover {
        text-decoration: underline; }
      svg {
        font-size: 16px;
        fill: #98e3e0;
        transform: rotate(-90deg);
        margin-right: 15px; } }
    .btn {
      border-color: #98e3e0;
      color: #98e3e0;
      margin: 0 20px;
      &:hover {
        background: #98e3e0;
        color: #fff; } }
    &__search {
      position: relative;
      button {
        background: none;
        height: 20px;
        width: 20px; }
      svg {
        font-size: 18px;
        fill: #98e3e0;
        position: relative;
        left: 0;
        top: 3px;
        z-index: 2;
        transition: all 0.5s;
        &:last-child {
          display: none; } }
      input {
        position: absolute;
        right: 0;
        top: -15px;
        width: 0;
        height: 55px;
        border: solid 2px #98e3e0;
        background-color: #ffffff;
        border-radius: 30px;
        opacity: 0;
        z-index: 1;
        font-size: 14px;
        font-weight: 500;
        color: #707070;
        transition: all 0.5s; }
      &.active {
        input {
          width: 400px;
          padding: 16px 30px;
          padding-right: 50px;
          opacity: 1;
          @include r(1200) {
            width: 370px; } }
        svg {
          left: -25px; } } } }



  &__logo {
    max-width: 300px;
    width: 100%;
    height: 120px;
    transition: all 0.4s;
    @include r(1366) {
      height: 60px;
      max-width: 220px; }
    @include r(992) {
      max-width: 365px;
      position: relative;
      right: -25px; }
    @include r(460) {
      padding: 10px 50px; }
    img {
      width: 100%;
      transition: all 0.4s;
      position: relative;
      top: 0; } }
  &__nav {
    display: flex;
    margin-left: -35px;
    @include r(992) {
      display: none; }
    li {
      position: relative;
      &::before {
        display: none; }
      ul {
        display: none; } }
    a, span {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.29;
      color: #ffffff;
      text-transform: uppercase;
      padding: 10px;
      display: block;
      @include r(1366) {
        font-size: 12px; }
      &:hover {
        text-decoration: underline; } }
    a {
      display: flex; }
    a.active:after {
      content: '';
      width: 13px;
      height: 13px;
      background-image: url('../img/arrow-right.svg');
      @include cover;
      display: block;
      margin-left: 10px;
      @include tr;
      transform: rotate(90deg); }
    span {
      cursor: pointer; }
    ul {
      background: #191919;
      border-radius: 7px;
      position: absolute;
      width: 140px;
      left: 0;
      top: 40px;
      span {
        display: block; }
      li {
        a, span {
          font-size: 13px;
          font-weight: 600;
          line-height: 1.23;
          color: #fdfdfd;
          text-align: left; }
        svg {
          @include tr;
          margin-left: 10px;
          font-size: 14px;
          margin-bottom: -2px;
          fill: #fff; }
        &.active {
          a.active:after {
            transform: rotate(0deg); } }
        ul {
          width: 240px;
          left: calc(100% + 5px);
          top: 0px;
          display: none;
          li {
            a, span {
              font-size: 13px;
              font-weight: 500;
              line-height: 1.23;
              color: #fdfdfd; } } } } } }

  &-statistics {
    @include r(1200) {
      display: none; }
    li {
      margin-left: 10px;
      background: #d8d8d8; } } }




.mobile-menu {
  position: fixed;
  top: 0;
  left: -400px;
  max-width: 370px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #fbfbfb;
  z-index: 10;
  transition: all 0.6s;
  @include r(992) {
    z-index: 1000; }
  &.active {
    left: 0;

    .mobile-menu__header {
      left: 0; } }
  &__header {
    position: fixed;
    top: 0;
    left: -400px;
    transition: all 0.6s;
    max-width: 370px;
    width: 100%;
    padding: 45px 50px;
    z-index: 2;
    // background-color: #ecf3fc
    background-image: url('../img/mobile-menu-img.png');
    @include cover;
    background-position: 0 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.29;
      color: #191919;
      max-width: 195px;
      width: 100%;
      margin-right: 10px; }
    svg {
      width: 20px;
      height: 20px;
      fill: $main_blue; } }
  &__body {
    padding: 25px 50px;
    padding-top: 155px;
    ul {
      ul {
        margin-left: 20px;
        margin-top: 25px;
        display: none;
        ul {
          margin-left: 40px; } }
      a {
        display: flex; }
      a.active:after {
        content: '';
        width: 13px;
        height: 13px;
        background-image: url('../img/arrow-right-black.svg');
        @include cover;
        display: block;
        margin-left: 10px;
        @include tr;
        transform: rotate(90deg); }
      li {
        margin-bottom: 40px;
        &::before {
          display: none; }
        &:last-child {
          margin-bottom: 25px; }
        a.click:after {
          transform: rotate(270deg); } }
      li, a {
        font-size: 13px;
        font-weight: bold;
        line-height: 1.23;
        color: #191919;
        text-transform: uppercase;
        li {
          font-weight: 600;
          margin-bottom: 20px;
          a {
            font-weight: 600; }
          li {
            font-weight: 500;
            a {
              font-weight: 500; } } } } } }
  .header-statistics {
    justify-content: space-around;
    margin-top: 50px;
    @include r(992) {
      display: flex !important;
      li {
        background: #dfebff; } } }
  &__logo {
    margin: 50px 0;
    img {
      width: 100%; } }
  .btn {
    @include relative-x; } }
