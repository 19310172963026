.footer {
  overflow: hidden;
  @include r(1366) {
    background-image: url('../img/footer-bg.png');
    @include cover;
    background-size: 1366px auto;
    background-position: 50% 0;
    @include r(767) {
      background: none; } }
  &__ask {
    background-image: url('../img/footer_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 100%;
    padding: 75px 0 55px;
    background-size: 1920px auto;
    margin-bottom: -85px;
    @include r(1366) {
      // background-size: 1366px auto
      // margin-bottom: -90px
      // padding: 95px 0 30px
      background-image: none;
      padding: 45px 0 60px; }
    @include r(767) {
      background-image: url('../img/footer_bg.png');
      padding: 60px 0 20px;
      margin-bottom: -85px;
      background-size: 1820px auto; }
    @include r(465) {
      background-size: 2220px auto; }

    &__row {
      display: flex;
      justify-content: center;
      align-items: center;
      @include r(767) {
        flex-direction: column; }
      strong {
        max-width: 435px;
        display: block;
        width: 100%;
        text-align: right;
        font-size: 26px;
        font-weight: 500;
        line-height: 1.38;
        color: $main_blue;
        margin-right: 60px;
        @include r(1366) {
          font-size: 22px;
          margin-right: 50px; }
        @include r(767) {
          font-size: 24px;
          max-width: 100%;
          margin-bottom: 10px;
          line-height: 1.2;
          margin-right: 0;
          text-align: center; } } } }
  &__content {
    position: relative;
    padding: 140px 0 60px;
    @include r(1366) {
      padding-top: 125px; }
    @include r(992) {
      padding-top: 120px; }
    @include r(767) {
      padding-top: 170px; }
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 0;
      width: 1920px;
      height: 100%;
      background-image: url('../img/bottom_bg.png');
      background-repeat: no-repeat;
      @include r(1366) {
        // width: 1366px
        // background-position: 40% 0
        display: none; }
      @include r(767) {
        display: block;
        @include cover;
        background-position: 50% 0; } }
    strong {
      padding-bottom: 14px;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.27;
      color: #191919;
      display: block; } }
  &__block {
    input {
      border-radius: 28px;
      border: solid 1px #afafaf;
      background-color: #ffffff;
      padding: 18px 30px 16px;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      width: 100%;
      @include placeholder-color(rgba(#000, 0.3));
      margin-bottom: 10px;
      @include r(992) {
        margin-bottom: 20px; } }
    .btn {
      margin-bottom: 30px;
      @include r(992) {
        @include relative-x; } }
    p {
      font-size: 14px;
      font-weight: normal;
      line-height: 1.29;
      color: #191919;
      margin-bottom: 20px;
      a {
        text-decoration: underline;
        color: #191919;
        font-weight: normal; } } }
  &__social {
    @include r(992) {
      display: none; }
    img {
      width: 42px;
      margin-right: 15px; } }
  .col-lg-7 {
    position: static; }
  .container {
    position: relative; }
  &__nav {
    display: flex;
    height: 100%;
    ul {
      max-width: 145px;
      width: 100%;
      margin-left: 50px;
      li {
        &::before {
          display: none; } }
      @include r(992) {
        display: none; } }
    a {
      font-size: 13px;
      font-weight: normal;
      line-height: 1.31;
      color: #191919;
      padding: 7px 0;
      display: block;
      &:hover {
        text-decoration: underline; } }
    img.logo {
      position: absolute;
      left: 20px;
      bottom: 0;
      width: 120px;
      @include r(992) {
        @include relative-x;
        height: 100px; } } } }

