.icon-arrow-down {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-left {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-award {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-burger {
  width: 1.05em;
  height: 1em;
  fill: #fff;
}
.icon-chat {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-check {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook-m {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-google {
  width: 0.98em;
  height: 1em;
  fill: initial;
}
.icon-mail {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-map {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-question {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-star {
  width: 1em;
  height: 1em;
  fill: initial;
}
