.process {
  padding-top: 60px;
  &__title {
    h3 {
      color: #707070; }
    p {
      color: #191919;
      margin-bottom: 20px; } }
  &__block {
    padding-top: 40px;
    @include r(992) {
      padding-top: 0; }
    &__decor-1 {
      height: 51px;
      @include center;
      img {
        height: 100%; }
      @include r(992) {
        display: none; } }
    &__decor-2 {
      height: 115px;
      @include center;
      img {
        height: 100%; }
      @include r(992) {
        display: none; } }
    h3 {
      margin-bottom: 30px; }
    p {
      color: #707070;
      margin-bottom: 20px;
      span {
        display: block; }
      a {
        color: $main_blue;
        text-decoration: underline;
        margin: 0 5px;
        display: inline-block; } }
    ul {
      margin-bottom: 20px; }
    li {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.5;
      color: #707070;
      padding-left: 20px;
      position: relative;
      @include r(1366) {
        font-size: 14px; }
      a {
        color: $main_blue;
        text-decoration: underline;
        margin: 0 4px;
        display: inline-block; }
      &::before {
        content: '';
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #707070;
        position: absolute;
        left: 0;
        top: 8px; }
      span {
        display: block; } }
    b {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.5;
      color: #707070;
      margin-bottom: 20px;
      display: block; }
    &__img {
      position: relative;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      min-height: 440px;
      padding-top: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 30px;
      @include r(992) {
        display: flex;
        justify-content: center;
        min-height: 300px;
        max-width: 300px;
        @include relative-x;
        margin-bottom: 30px; }
      &--secondary {
        display: flex;
        justify-content: flex-end;
        span {
          left: auto !important;
          right: 220px; } }
      .img {
        width: 300px;
        height: 300px;
        overflow: hidden;
        border-radius: 50%;
        @include r(1200) {
          width: 220px;
          height: 220px; }
        img {
          width: 100%;
          height: 100%;
          @include object-fit(cover); } }
      span {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        @include center;
        background-color: #7d8cfd;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.21;
        color: #ffffff;
        position: absolute;
        left: 10px;
        top: 280px;
        @include r(992) {
          right: auto !important;
          left: calc(50% - 80px) !important;
          transform: translate(-50%);
          top: 220px;
          width: 60px;
          height: 60px; } } } } }
