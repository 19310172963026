.aside {
  padding: 40px 50px 10px;
  border-radius: 28px;
  background-color: #fbfbfb;
  @include r(767) {
    display: none; }
  h3 {
    margin-bottom: 25px; }
  ul {
    margin-bottom: 40px; }
  li {
    margin-bottom: 15px;
    &::before {
      display: none; }
    a {
      color: $main_blue; } } }
