.calculator {
  padding: 100px 0;
  @include r(767) {
    padding: 35px 0; }
  .title {
    text-align: center;
    margin-bottom: 60px;
    p {
      margin-top: 15px; } }
  .tabs {
    max-width: 830px;
    width: 100%;
    @include relative-x;
    &__caption {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 60px;
      li {
        font-size: 14px;
        font-weight: 600;
        color: $main_blue;
        border: solid 1px $main_blue;
        border-bottom: none;
        border-radius: 25px 25px 0 0;
        padding: 14px 50px 12px;
        margin: 0 15px;
        text-transform: uppercase;
        @include tr;
        cursor: pointer;
        &::before {
          display: none; }
        @include r(767) {
          border: none; }
        @include r(500) {
          padding: 14px 20px 12px;
          margin: 0 10px; }
        &.active {
          background: $main_blue;
          color: #fff;
          padding: 18px 60px 16px;
          @include r(500) {
            padding: 16px 25px 14px; } } } }

    &__block {
      border: solid 2px $main_blue;
      background-color: #ffffff;
      border-radius: 25px;
      padding: 24px 80px 34px;
      @include r(767) {
        padding: 22px 45px; }
      @include r(460) {
        padding: 22px 15px; } }
    &__content {
      display: none;
      &.active {
        display: block; }
      h5 {
        text-align: center;
        display: block;
        color: #707070;
        margin-bottom: 50px;
        position: relative;
        @include r(767) {
          font-size: 16px; } }
      p {
        font-size: 12px;
        font-weight: normal;
        color: #191919;
        text-align: center;
        margin-top: 35px;
        @include r(767) {
          max-width: 246px;
          width: 100%;
          @include relative-x; }
        span {
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase; } } } }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .btn {
      @include r(767) {
        margin: 0 auto; } } }
  &__result {
    @include r(767) {
      padding-top: 20px; }
    li {
      text-align: right;
      font-size: 16px;
      line-height: 1.2;
      color: #414141;
      margin-bottom: 15px;
      @include r(767) {
        font-size: 16px; }
      &::before {
        display: none; }
      @include r(460) {
        font-size: 18px; }
      span {
        font-weight: 600;
        color: $main_blue;
        i {
          font-style: normal; } }
      strong {
        font-size: 28px;
        font-weight: normal;
        line-height: 1.57;
        color: #840000;
        b {
          font-size: 36px;
          font-weight: 600;
          i {
            font-style: normal; } } } } }
  &__select {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.29;
      text-align: right;
      color: #414141;
      text-transform: uppercase;
      display: inline-block;
      margin-right: 30px; }
    .selectric {
      width: 115px;
      height: 50px;
      .button {
        right: 20px;
        font-size: 23px; }
      .label {
        justify-content: center;
        margin-left: 10px;
        font-size: 14px;
        color: $main_blue;
        font-weight: bold; } }
    .selectric-wrapper.active {
      .label {
        color: $main_blue; } } } }
