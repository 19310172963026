.cost {
  padding-top: 60px;
  &__title {
    margin-bottom: 70px;
    p {
      color: #707070; } }


  &__desctop {
    display: flex;
    position: relative;
    margin-bottom: 65px;
    @include r(992) {
      display: none; } }
  &__block-info {
    background-color: #b8c3ff;
    margin-top: 163px;
    width: 160px;
    padding-left: 40px;
    border-radius: 22px 0 0 0;
    &::before {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 0;
      width: calc(100% - 20px);
      height: 60px;
      display: block;
      background: #b8c3ff;
      border-radius: 0 0 22px 22px;
      z-index: -1; }
    li {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.19;
      text-align: center;
      color: #191919;
      @include center;
      padding: 20px 0;
      min-height: 65px;
      border-bottom: dotted 1px #d8d9dd;
      &::before {
        display: none; }
      &:last-child {
        height: 140px;
        flex-direction: column;
        border-bottom: none; } } }
  &__block {
    width: 100%;
    position: relative;
    &--check {
      &::before {
        content: '';
        position: absolute;
        border-radius: 22px;
        background-image: linear-gradient(to bottom, #e6efff, #ffffff);
        width: 100%;
        height: 90%;
        position: absolute;
        left: 0;
        top: -60px;
        z-index: 1; } }
    &__decor {
      display: flex;
      flex: 1;
      border-radius: 22px;
      border: solid 3px #f6f8fe;
      background-color: #ffffff;
      padding-top: 30px;
      padding-right: 40px; }
    &__select {
      @include center;
      .selectric {
        height: 36px;
        width: 135px;
        .button {
          font-size: 16px;
          width: 10px;
          height: 5px;
          right: 15px;
          color: #707070; }
        .label {
          font-size: 14px;
          font-weight: normal;
          margin-left: 10px;
          color: #000; } } }
    &__header {
      min-height: 130px;
      position: relative;
      z-index: 3;
      @include r(992) {
        height: 90px;
        min-height: auto; }
      a {
        font-size: 20px;
        font-weight: 600;
        color: $main_blue;
        line-height: 1.29;
        display: block;
        text-align: center; }
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.29;
        display: block;
        text-align: center;
        color: #191919; }
      b {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.29;
        text-align: center;
        color: #191919;
        display: block;
        margin-bottom: 20px;
        @include r(992) {
          margin-bottom: 10px; } } }
    &__body {
      position: relative;
      z-index: 2;
      li {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.22;
        text-align: center;
        color: #707070;
        @include center;
        min-height: 65px;
        padding: 20px 0;
        border-bottom: dotted 1px #d8d9dd;
        &::before {
          display: none; }
        span {
          font-size: 18px;
          font-weight: 600;
          color: #ff0000;
          &:nth-child(3) {
            color: #ff6f00; } }
        b {
          font-size: 12px;
          font-weight: normal;
          line-height: 1.5;
          text-align: center;
          color: #191919;
          display: block;
          margin-bottom: 10px; }
        &:last-child {
          height: 140px;
          flex-direction: column;
          border-bottom: none; } } } }




  &__mobile {
    display: none;
    width: calc(100% + 40px);
    margin-left: -20px;
    @include r(450) {
      width: 100%;
      margin-left: 0; }
    @include r(992) {
      display: block; }
    .swiper-container {}
    .swiper-slide {
      display: flex;
      justify-content: center; } }
  &__slide {
    width: 290px;
    &__body {
      border-radius: 28px;
      background-image: linear-gradient(to bottom, #dfebff, #ffffff);
      padding: 20px 20px;
      li {
        display: flex;
        padding: 25px 0;
        border-bottom: dotted 1px #d8d9dd;
        &:before {
          display: none; }
        &:last-child {
          border-bottom: none;
          min-height: 150px;
          @include r(767) {
            min-height: auto; } }
        p {
          font-size: 16px;
          font-weight: 600;
          color: #191919;
          width: 100%;
          text-align: right; }
        span {
          font-size: 18px;
          font-weight: 600;
          color: #707070;
          width: 100%;
          text-align: center; }
        strong {
          font-size: 18px;
          font-weight: 600;
          color: #ff0000;
          display: block;
          &:nth-child(3) {
            color: #ff6f00; } }
        b {
          font-size: 12px;
          font-weight: normal;
          line-height: 1.5;
          text-align: center;
          color: #191919;
          margin-bottom: 15px;
          display: block;
          width: 74px;
          @include relative-x; } } } }
  .swiper-cost-next, .swiper-cost-prev {
    width: 60px;
    height: 60px;
    background: $main_blue;
    border-radius: 50%;
    @include center;
    @include tr;
    top: 183px;
    @include r(450) {
      width: 30px;
      height: 30px;
      top: 200px; }
    svg {
      width: 40px;
      height: 40px;
      fill: #fff;
      @include r(450) {
        width: 20px;
        height: 20px; } }
    &.swiper-button-disabled {
      opacity: 0; } }
  .swiper-cost-next {
    right: 0; }
  .swiper-cost-prev {
    left: 0; }





  &__text {
    padding: 75px 0;
    @include r(767) {
      padding: 40px 0; }
    &--secondary {
      padding-top: 0; }
    p {
      margin-bottom: 20px;
      span {
        display: block; } }
    a {
      margin-right: 30px;
      text-decoration: underline;
      font-size: 16px;
      color: $main_blue; }
    ol {
      margin-bottom: 20px; }
    h4 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px; }
    &__img {
      display: flex;
      align-items: flex-start;
      @include r(992) {
        flex-direction: column;
        align-items: center; }
      img {
        width: 215px;
        margin-left: 30px;
        margin-top: 30px; } } } }
