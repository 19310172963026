html {}
body {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  line-height: 1.38;
  color: #707070;
  @include r(1366) {
    font-size: 14px; } }
.container {
  box-sizing: border-box;
  padding: 0 30px;
  @include rmin(1200) {
    max-width: 1170px !important; }
  @include r(450) {
    padding: 0 15px; } }
a {
  text-decoration: none;
  transition: all 0.3s;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: #0066c0; }
input[type="submit"] {
  appearance: none;
  -webkit-appearance: none; }
textarea, input {
  resize: none;
  font-family: 'Montserrat', sans-serif; }

.out {
  padding-top: 230px;
  overflow: hidden;
  @include r(992) {
    padding-top: 120px; }
  &-home {
    padding-top: 120px;
    @include r(1366) {
      padding-top: 90px; }
    @include r(992) {
      padding-top: 0px; } } }

//color
$main_blue: #0066c0;

//styleguide

ul {
  li {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    color: #707070;
    @include r(1366) {
      font-size: 14px; }
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-top: -2px;
      margin-right: 10px;
      background: #707070;
      display: inline-block; } } }


.title {
  text-align: center; }
h1, .title {
  font-size: 42px;
  margin: 10px 0;
  font-weight: 600;
  line-height: 1.36;
  color: #191919;
  @include r(1366) {
    font-size: 36px; }
  a {
    font-size: 42px;
    font-weight: 600;
    line-height: 1.36;
    color: $main_blue;
    @include r(1366) {
      font-size: 36px; } }
  @include r(767) {
    font-size: 32px;
    a {
      font-size: 32px; } } }
h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 1.33;
  color: #707070;
  margin: 10px 0;
  a {
    color: $main_blue;
    font-size: 30px;
    font-weight: 600;
    line-height: 1.33; }
  @include r(1366) {
    font-size: 26px;
    a {
      font-size: 26px; } }
  @include r(767) {
    font-size: 24px;
    a {
      font-size: 24px; } } }
h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.38;
  color: #191919;
  margin: 7px 0;
  a {
    color: $main_blue;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.38; }
  @include r(1366) {
    font-size: 22px;
    a {
      font-size: 22px; } }
  @include r(767) {
    font-size: 24px;
    a {
      font-size: 24px; } } }
h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.36;
  color: #191919;
  margin: 5px 0;
  a {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.36;
    color: $main_blue; }
  @include r(1366) {
    font-size: 20px;
    a {
      font-size: 20px; } }
  @include r(767) {
    font-size: 18px;
    a {
      font-size: 18px; } } }
h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  color: #191919;
  margin-bottom: 5px;
  a {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    color: $main_blue;
    margin-bottom: 5px; }
  @include r(1366) {
    font-size: 14px;
    a {
      font-size: 14px; } }
  @include r(767) {
    font-size: 18px;
    a {
      font-size: 18px; } } }
p {
  font-size: 16px;
  line-height: 1.38;
  color: #707070;
  margin-bottom: 10px;
  @include r(1366) {
    font-size: 14px; }
  a {
    font-weight: 500;
    color: $main_blue;
    font-size: 16px;
    line-height: 1.38;
    @include r(1366) {
      font-size: 14px; }
    &:hover {
      text-decoration: underline; } } }







//button-fixed
.button-fixed {
  &__covid {
    width: 60px;
    height: 60px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
    background-color: #ff5003;
    border-radius: 31px;
    transition: all 0.3s;
    overflow: hidden;
    position: relative;
    position: fixed;
    bottom: 40px;
    right: 120px;
    z-index: 10;
    @media only screen and (max-width: 374px) {
      right: 110px; }
    @media only screen and (max-height: 740px) {
      right: 100px;
      bottom: 10px; }
    button.open {
      background: none;
      position: absolute;
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;
      img {
        width: 32px; } }
    &__content {
      width: 240px;
      position: absolute;
      right: 50px;
      padding: 0px 32px;
      transition: all 0.3s;
      @media only screen and (max-width: 374px) {
        width: 190px;
        padding: 0 7px; } }
    button.close {
      background: none;
      padding: 23px 0; }
    a {
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase;
      padding: 23px 0; }
    svg {
      font-size: 13px;
      fill: #fff;
      margin-left: 20px;
      cursor: pointer;
      @media only screen and (max-width: 374px) {
        margin-left: 12px; } }
    &.active {
      width: 240px;
      @media only screen and (max-width: 374px) {
        width: 200px; }
      .button-fixed__covid__content {
        right: 0; } } }

  &__chat {
    background: rgb(24, 56, 150);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 10;
    @include rh(740) {
      right: 20px;
      bottom: 10px; }
    svg {
      font-size: 32px;
      fill: #fff; } } }

//button
.btn {
  padding: 16px 50px 14px;
  border: solid 2px $main_blue;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 28px;
  @include r(1366) {
    padding: 12px 34px 10px;
    font-size: 12px; }
  &-primary {
    background-color: $main_blue;
    color: #fff;
    &:hover {
      background: #fff;
      color: $main_blue; } }
  &-secondary {
    background: none;
    color: $main_blue;
    &:hover {
      background: $main_blue;
      color: #fff; } } }


.decor-1 {
  background-image: url('../img/bg_1_lite.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  @include r(767) {
    background-size: 1400px auto;
    background-position: 100% 0; } }
.decor-2 {
  background-image: url('../img/bg_2_lite.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  @include r(767) {
    background-size: 1400px auto;
    background-position: 100% 50px; } }







.stars {
  display: flex;
  align-items: center;
  li {
    margin: 0 2px;
    &::before {
      display: none; } }
  svg {
    width: 13px;
    height: 13px;
    fill: $main_blue; }
  span {
    font-size: 30px;
    font-weight: 600;
    margin-left: 5px; } }


@include r(767) {
	.hidden-xs {
		display: none !important; } }
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.hidden-sm {
		display: none !important; } }
@include rmin(1024) {
	.hidden-md {
		display: none !important; } }

.flex {
  display: flex; }





.selectric {
  border-radius: 28px;
  border: solid 1px #afafaf;
  background-color: #ffffff;
  height: 57px;
  @include r(1366) {
    height: 50px; }
  .button {
    font-size: 27px;
    top: calc(50% - 2px);
    right: 25px;
    transform: translate(0, -50%);
    @include center;
    justify-content: flex-start;
    @include tr;
    width: 15px;
    height: 10px; }
  &-open {
    .button {
      transform: rotate(180deg); } }
  .label {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 1.22;
    color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 30px;
    @include r(1366) {
      font-size: 14px; } } }
.selectric-items {
  border-radius: 28px;
  overflow: hidden;
  li {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.22;
    color: $main_blue;
    text-align: center;
    &:before {
      display: none; }
    &.selected {
      background: $main_blue;
      color: #fff; } } }

.selectric-wrapper.active {
  .label {
    color: #000; } }





.pages {
  padding: 150px 0 50px;
  h1 {
    font-size: 40px;
    color: $black;
    margin-bottom: 30px;
    display: block;
    text-align: center; }
  li {
    @include center;
    a {
      font-size: 18px;
      color: $black;
      line-height: 2;
      &:hover {
        text-decoration: underline; } } } }






.question {
  position: relative;
  display: inline-block;
  @include r(767) {
    position: static; }
  .open {
    margin-left: 6px;
    svg {
      font-size: 24px;
      fill: #0066c0;
      position: relative;
      top: 6px; } }
  &__dropdown {
    position: absolute;
    top: calc(100% + 15px);
    left: 50%;
    transform: translate(-50%);
    width: 320px;
    padding: 45px 35px;
    border-radius: 16px;
    background-color: #dfebff;
    z-index: 8;
    display: none;
    @include r(992) {
      right: -50px;
      width: 250px;
      left: auto;
      transform: none; }
    @include r(767) {
      top: auto;
      right: auto;
      left: 50%;
      transform: translate(-50%);
      max-width: 600px;
      width: 100%; }
    .close {
      position: absolute;
      top: 17px;
      right: 21px;
      svg {
        font-size: 16px;
        fill: #0066c0; } }
    p {
      font-size: 12px;
      font-weight: normal;
      line-height: 1.25;
      text-align: left !important;
      color: #191919;
      margin: 0 !important;
      padding: 0 !important; } } }
