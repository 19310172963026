.reviews {
  padding-top: 60px;
  padding-bottom: 100px;
  @include r(767) {
    padding-bottom: 0; }
  .btn {
    @include relative-x;
    margin-top: 30px; }
  &__text {
    margin-bottom: 80px;
    @include r(767) {
      margin-bottom: 20px; }
    p {
      margin-bottom: 20px; } }
  &__block {
    width: 100%;
    position: relative;
    padding-top: 110px;
    margin-bottom: 65px;
    &--primary {
      .reviews__block__header {
        background-image: linear-gradient(89deg, #1ba8a8 3%, #2699b7 100%); }
      .reviews__list {
        border: solid 3px #2699b7;
        svg {
          fill: #1ba8a8; } } }
    &--secondary {
      .reviews__block__header {
        background-image: linear-gradient(89deg, #3a55e7 3%, #647aea 100%); }
      .reviews__list {
        border: solid 3px #6379ea;
        svg {
          fill: #0066c0; } } }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 30px 25px;
      border-radius: 34px;
      height: 100%;
      max-height: 680px;
      width: 100%;
      position: absolute;
      top: 0;
      left: -10px;
      z-index: -1;
      &__row {
        display: flex;
        align-items: center;
        padding-top: 10px;
        svg {
          fill: #fff;
          width: 45px;
          height: 45px;
          margin-right: 20px; }
        p {
          font-size: 26px;
          font-weight: normal;
          color: #ffffff;
          @include r(1200) {
            display: none; } } }
      &__assessment {
        svg {
          fill: #fff; }
        span {
          color: #fff; }
        b {
          font-size: 14px;
          font-weight: normal;
          color: #ffffff; } } } }
  &__list {
    border-radius: 34px;
    background-color: #ffffff;
    padding: 25px 35px;
    @include r(450) {
      padding: 25px 15px; }
    &__block {
      border-bottom: 1px solid #d8d9dd;
      padding: 20px 0;
      &:last-child {
        border-bottom: none; }
      strong {
        font-size: 14px;
        font-weight: bold;
        color: #707070;
        text-transform: uppercase;
        margin-bottom: 5px;
        display: block; }
      .stars {
        margin-bottom: 5px; }
      p {
        span {
          display: block; } } } } }


