.home-providers {
  padding: 0px 0 80px;
  @include r(767) {
    padding: 40px 0; }
  .title {
    margin-bottom: 60px;
    span {
      @include r(767) {
        display: none; } } }
  &__row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
  &__block {
    max-width: 25%;
    flex: 0 0 25%;
    @include center;
    margin-bottom: 35px;
    @include r(992) {
      margin: 0 10px;
      margin-bottom: 15px;
      max-width: calc(25% - 20px);
      flex: 0 0 25%; }
    img {
      height: 150px;
      @include r(992) {
        height: auto;
        width: 100%; } }
    &--secondary {
      img {
        height: 100px;
        @include r(992) {
          height: auto; } } } } }
