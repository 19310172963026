.offers {
  padding-top: 60px;
  &__title {
    h2 {
      margin-bottom: 40px; } }
  &__block {
    border-radius: 22px;
    padding: 35px 45px 35px 40px;
    margin-bottom: 20px;
    display: flex;
    position: relative;
    @include r(767) {
      flex-direction: column-reverse;
      align-items: center;
      padding: 30px 35px;
      padding-bottom: 120px; }
    @include r(450) {
      padding: 20px 15px;
      padding-bottom: 120px; }
    &__text {
      flex: 1;
      h3 {
        color: #fff;
        margin-bottom: 10px; }
      p {
        color: #fff;
        margin-bottom: 20px;
        span {
          display: block; } } }
    &__btn {
      width: 160px;
      @include center-column;
      .btn {
        border-color: #fff;
        color: #fff;
        @include r(767) {
          position: absolute;
          bottom: 50px; }
        &:hover {
          color: #000;
          background: #fff; } } }
    &__img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      background: #fff;
      overflow: hidden;
      @include center;
      margin-bottom: 20px;
      @include r(767) {
        margin-bottom: 40px; }
      span {
        font-size: 59px;
        font-weight: bold; }
      img {
        width: 100%; } } } }

