.section-process {
  padding: 70px 0;
  .title {
    margin-bottom: 90px;
    text-align: center;
    @include r(1366) {
      margin-bottom: 75px; }
    @include r(767) {
      margin-bottom: 60px; } }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px;
    @include r(767) {
      margin-bottom: 60px; }
    &__img {
      width: 205px;
      height: 205px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 60px;
      @include r(767) {
        margin-bottom: 30px; }
      img {
        width: 100%;
        height: 100%;
        @include object-fit(cover); } }
    span {
      position: absolute;
      top: 150px;
      left: calc(50% - 70px);
      transform: translate(-50%);
      width: 49px;
      height: 49px;
      background-color: #7d8cfd;
      @include center;
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      border-radius: 50%; }
    p {
      font-size: 20px;
      font-weight: 600;
      color: #191919;
      display: block;
      padding: 15px 0;
      text-align: center; } } }
