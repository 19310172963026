.section-reviews {
  padding: 70px 0;
  @include r(767) {
    padding: 30px 0; }
  .title {
    text-align: center;
    margin-bottom: 50px; }
  &__block {
    border: solid 3px #dfebff;
    border-radius: 34px;
    padding: 20px;
    height: calc(100% - 30px);
    margin-bottom: 30px;
    strong, span {
      font-size: 14px;
      color: #414141;
      margin: 0; }
    strong {
      text-transform: uppercase; }
    span {
      margin-bottom: 10px;
      display: block; }
    .stars {
      margin-bottom: 15px; }
    p {
      font-size: 14px;
      line-height: 1.29;
      color: #414141; } }
  &__statistics {
    border-radius: 22px;
    background-color: #ffffff;
    padding: 20px;
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    @include r(767) {
      width: calc(100% + 30px);
      margin-left: -15px;
      border-radius: 0; }
    @include r(424) {
      padding: 20px 10px; }
    .block {
      display: flex;
      align-items: center; }
    .img {
      margin-right: 10px;
      svg {
        height: 45px;
        width: 45px;
        fill: #000; } }
    b {
      font-size: 24px;
      font-weight: 500;
      color: #707070;
      span {
        font-size: 14px;
        font-weight: normal;
        @include r(1200) {
          display: none; } } }
    .btn {
      @include r(424) {
        padding: 14px 19px 12px; } } } }
