.location {
  padding-top: 60px;
  &__title {
    margin-bottom: 20px; }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    h3 {
      margin-bottom: 20px;
      width: 100%; }
    ul {
      max-width: 250px;
      width: 100%;
      @include r(767) {
        width: 50%; }
      @include r(450) {
        width: 100%; } }
    li {
      color: #191919;
      font-weight: 500;
      &::before {
        display: none; } } } }
